import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import apply from '../views/apply.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '台胞(台商)子女就学网上备案系统',
    component: index
  },
  {
    path: '/apply',
    name: '申请台胞(台商)子女就学 网上备案系统',
    component: apply
  },
  {
    path: '/poor',
    name: '在厦定居台胞扶贫济困专项资金申报系统',
    component: () => import('../views/poor/poor.vue')
  },
  {
    path: '/dee',
    name: '在厦定居台胞扶贫济困专项资金申报系统',
    component: () => import('../views/poor/dee.vue')
  },
  {
    path: '/add',
    name: '台胞扶贫济困专项资金申请',
    component: () => import('../views/poor/addPoor.vue')
  },
  {
    path: '/next',
    name: '台胞扶贫济困专项资金申请',
    component: () => import('../views/poor/nextStep.vue')
  },
  {
    path: '/third',
    name: '台胞扶贫济困专项资金申请',
    component: () => import('../views/poor/thirdStep.vue')
  },
  {
    path: '/finaly',
    name: '台胞扶贫济困专项资金申请',
    component: () => import('../views/poor/finalyStep.vue')
  },
  {
    path: '/collect',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/index.vue')
  },
  {
    path: '/addInfo',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/addInfo.vue')
  },
  {
    path: '/workInfo',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/workInfo.vue')
  },
  {
    path: '/nextInfo',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/nextInfo.vue')
  },
  {
    path: '/economyInfo',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/economyInfo.vue')
  },
  {
    path: '/familyInfo',
    name: '厦门市定居台胞信息填报',
    component: () => import('../views/infoCollect/familyInfo.vue')
  },
  {
    path: '/resident',
    name: '厦门市常住台胞信息填报',
    component: () => import('../views/resident/index.vue')
  },
  {
    path: '/addResident',
    name: '厦门市常住台胞信息填报',
    component: () => import('../views/resident/addInfo.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
