import qs from 'qs'

function processUrloa() {
  const url = window.location.href
  // 解决多次登录url添加重复的code与state问题
  const urlParams = qs.parse(url.split('?')[1])
  let redirectUrl = url
  if (urlParams.OA) {
    delete urlParams.OA
    const query = qs.stringify(urlParams)
    if (query.length) {
      redirectUrl = `${url.split('?')[0]}?${query}`
    } else {
      redirectUrl = `${url.split('?')[0]}`
    }
  }
  console.log("redirectUrl",redirectUrl)
  return redirectUrl
}


export function storage (type, key, val) { // 本地缓存
  let data = {}
  switch (type) {
    case 'set':
      localStorage.setItem(key, JSON.stringify(val))
      break
    case 'get':
      data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : ''
      break
    case 'remove':
      localStorage.removeItem(key)
      break
  }
  return data
}
const blobToDataURL = (blob, cb) => {
  let reader = new FileReader();
  reader.onload = function (evt) {
    let base64 = evt.target.result;
    cb(base64);
  };
  reader.readAsDataURL(blob);
}

function getUrlToBase64 (url) {
  var image = new Image();
  image.crossOrigin = '';
  image.src = url;
  image.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.width, image.height);
    var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
  }
}

export default {
  processUrloa,
  storage,
  blobToDataURL,
  getUrlToBase64
}
