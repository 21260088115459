import { fetchget, fetchpost } from './apiConfig.js'

async function login (data) {
  var item = await fetchpost('/admin/base/open/wechatlogin', data)
  return item
}

async function getItem (data) {
  var item = await fetchget('/app/base/param/param', data)
  return item
}

async function addStudents (data) {
  var item = await fetchpost('/app/demo/students/add', data)
  return item
}

async function getStudentsDetail (data) {
  var item = await fetchget('/app/demo/students/info', data)
  return item
}

async function updataStudents (data) {
  var item = await fetchpost('/app/demo/students/update', data)
  return item
}

async function getStudentList (data) {
  var item = await fetchget('/app/demo/students/other', data)
  return item
}

async function getSignature (data) {
  var item = await fetchpost('/admin/base/open/getWechatSdk', data)
  return item
}

async function uploads (data) {
  var item = await fetchpost('/app/demo/file/upload', data)
  return item
}

// 资金申请
async function getManList (data) {
  var item = await fetchget('/app/demo/users/other', data)
  return item
}
async function updataMan (data) {
  var item = await fetchpost('/app/demo/users/update', data)
  return item
}
async function addMan (data) {
  var item = await fetchpost('/app/demo/users/add', data)
  return item
}
async function getManDetail (data) {
  var item = await fetchget('/app/demo/users/info', data)
  return item
}
async function getduplicate (data) {
  var item = await fetchget('/app/demo/users/duplicate', data)
  return item
}

// 定居台胞信息采集
async function getMsgCollect (data) {
  var item = await fetchget('/app/demo/twinfo/other', data)
  return item
}
async function updataMsgCollect (data) {
  var item = await fetchpost('/app/demo/twinfo/update', data)
  return item
}
async function addMsgCollect (data) {
  var item = await fetchpost('/app/demo/twinfo/add', data)
  return item
}
async function getMsgCollectDetail (data) {
  var item = await fetchget('/app/demo/twinfo/info', data)
  return item
}

// 常住台胞信息采集
async function getResidenceCollect (data) {
  var item = await fetchget('/app/demo/retwinfo/other', data)
  return item
}
async function updataResidenceCollect (data) {
  var item = await fetchpost('/app/demo/retwinfo/update', data)
  return item
}
async function addResidenceCollect (data) {
  var item = await fetchpost('/app/demo/retwinfo/add', data)
  return item
}
async function getResidenceCollectDetail (data) {
  var item = await fetchget('/app/demo/retwinfo/info', data)
  return item
}

// 测试登录
async function testLogin (data) {
  var item = await fetchpost('/admin/base/open/wechatloginid', data)
  return item
}

export default {
  login,
  getItem,
  addStudents,
  getStudentsDetail,
  updataStudents,
  getStudentList,
  getSignature,
  uploads,
  getManList,
  updataMan,
  addMan,
  getManDetail,
  getMsgCollect,
  updataMsgCollect,
  addMsgCollect,
  getMsgCollectDetail,
  getduplicate,
  testLogin,
  getResidenceCollect,
  updataResidenceCollect,
  addResidenceCollect,
  getResidenceCollectDetail
}
