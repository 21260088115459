import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css';
import config from '@/config'
import { Toast, Dialog } from 'vant';
import utils from '@/utils'
import api from './api/api';
import wxShare from './api/wxShare';

utils.storage('remove', "userinfo");

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()


Vue.config.productionTip = false

Vue.use(Vant)
Vue.prototype.$config = config
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$utils = utils
Vue.prototype.$api = api
Vue.prototype.$wxShare = wxShare

router.beforeEach((from, to, next) => {
  document.title=from.name;
  if (from.query.code) {
    api.login({code: from.query.code}).then(res => {
      console.log(res)
      if (res.code === config.SUCCESS_CODE) {
        utils.storage('set', 'userinfo', res)
        let querys= from.query
        delete querys.code
        delete querys.state;
        router.replace({path:from.path,query:querys})
      } else {
        Toast('登录失败')
      }
      next()
    }).catch(err => {
      utils.storage('remove', "userinfo");
      console.log(err);
      next()
    })
  } else {
    next()
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
