<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/twxsjdxin@2x.png" alt="">
    </div>
    <div class="apply-wrap">
      <div class="apply-box">
        <div class="apply-title"><span></span>填报人</div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>姓名</div>
            <input class="apply-text" v-model="form.name" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>电话</div>
            <input class="apply-text" v-model="form.tel" readonly @click="telKeyboard = true" type="text" placeholder="请输入">
            <van-number-keyboard
              v-model="form.tel"
              :show="telKeyboard"
              :maxlength="11"
              @blur="telKeyboard = false"
            />
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>填报人与适龄儿童的关系</div>
            <input class="apply-text" v-model="form.relationship" type="text" placeholder="请输入">
          </div>
        </div>
      </div>
      <div class="apply-box">
        <div class="apply-title"><span></span>适龄儿童基本信息</div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>姓名</div>
            <input class="apply-text" v-model="form.childrenName" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" @click="openPop('sex')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>性别</div>
            <input class="apply-text" v-model="form.childSex" type="text" readonly placeholder="请选择性别">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line" @click="openPop('time')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>出生日期</div>
            <input class="apply-text" type="text" v-model="form.childbrith" readonly placeholder="请选择">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>台胞证号码</div>
            <input class="apply-text" v-model="form.twId" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle" style="padding-left: 10px;">居住证号码</div>
            <input class="apply-text" v-model="form.residenceId" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>住房性质</div>
            <van-radio-group v-model="form.houseType" direction="horizontal">
              <van-radio :name="1">购房</van-radio>
              <van-radio :name="2">租房</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="apply-line" @click="openPop('map')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>{{form.houseType === 1 ? '所购房产' : ''}}所在区</div>
            <input class="apply-text" type="text" v-model="form.place" readonly placeholder="请选择">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>{{form.houseType === 1 ? '所购房产' : ''}}详细地址</div>
            <input class="apply-text" v-model="form.detailPlace" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="form.houseType === 1">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>产权证号</div>
            <input class="apply-text" v-model="form.propertyId" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="form.houseType === 1">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>产权人姓名</div>
            <input class="apply-text" v-model="form.propertyName" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="form.houseType === 1">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>产权人与适龄儿童关系</div>
            <input class="apply-text" v-model="form.propertyRelation" type="text" placeholder="请输入">
          </div>
        </div>
      </div>

      <div class="apply-box">
        <div class="apply-tops">
          <div class="apply-title"><span></span>父母或其他法定监护人情况(监护人1)</div>
        </div>
        <div class="apply-line" @click="openPop('relation1')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>关系</div>
            <input class="apply-text" v-model="form.relation1" type="text" readonly placeholder="请选择">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line" v-if="form.relation1 === '其他'">
          <input type="text" class="relation-input" v-model="form.relation1s" placeholder="请输入其他关系">
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>姓名</div>
            <input class="apply-text" v-model="form.relation1Name" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>台胞证号码（大陆居民身份证）</div>
            <input class="apply-text" type="text" v-model="form.relation1TwId" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle" style="padding-left: 10px;">居住证号码</div>
            <input class="apply-text" v-model="form.relation1ResidenceId" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>单位或公司名称</div>
            <input class="apply-text" v-model="form.relation1Company" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" @click="openPop('job1')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>职务</div>
            <input class="apply-text" v-model="form.relation1Job" readonly type="text" placeholder="请选择">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line" v-if="form.relation1Job === '其他'">
          <input type="text" class="relation-input" v-model="form.relation1Jobs" placeholder="请输入职务">
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>联系电话</div>
            <input class="apply-text" v-model="form.relation1Tel" type="text" placeholder="请输入">
          </div>
        </div>
      </div>

      <div class="apply-box">
        <div class="apply-tops">
          <div class="apply-title"><span></span>父母或其他法定监护人情况(监护人2)</div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>监护人2</div>
            <van-radio-group v-model="hasGuardian2" direction="horizontal">
              <van-radio :name="1">有</van-radio>
              <van-radio :name="2">无</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="apply-line" @click="openPop('relation2')" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>关系</div>
            <input class="apply-text" v-model="form.relation2" readonly type="text" placeholder="请选择">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line" v-if="form.relation2 === '其他' && hasGuardian2 === 1">
          <input type="text" class="relation-input" v-model="form.relation2s" placeholder="请输入其他关系">
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>姓名</div>
            <input class="apply-text" v-model="form.relation2Name" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>台胞证号码（大陆居民身份证）</div>
            <input class="apply-text" type="text" v-model="form.relation2TwId" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle" style="padding-left: 10px;">居住证号码</div>
            <input class="apply-text" v-model="form.relation2ResidenceId" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>单位或公司名称</div>
            <input class="apply-text" v-model="form.relation2Company" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)" @click="openPop('job2')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>职务</div>
            <input class="apply-text" v-model="form.relation2Job" type="text" readonly placeholder="请输入">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
        <div class="apply-line" v-if="form.relation2Job === '其他' && hasGuardian2 === 1">
          <input type="text" class="relation-input" v-model="form.relation2Jobs" placeholder="请输入职务">
        </div>
        <div class="apply-line" v-if="!(hasGuardian2 === 2)">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>联系电话</div>
            <input class="apply-text" v-model="form.relation2Tel" type="text" placeholder="请输入">
          </div>
        </div>
      </div>

      <div class="apply-box">
        <div class="apply-title"><span></span>原就读学校及年级</div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>原就读学校</div>
            <input class="apply-text" v-model="form.oldSchool" type="text" placeholder="请输入">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>年级</div>
            <input class="apply-text" v-model="form.class" type="text" placeholder="请输入">
          </div>
        </div>
      </div>
      <div class="apply-box">
        <div class="apply-title"><span></span>申请就读年级</div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>申请就读年级</div>
            <div>
              <van-radio-group v-model="form.grade" @change="changeClass" direction="horizontal">
                <van-radio :name="item.id" v-for="(item, i) in classList" :key="i">{{item.value}}</van-radio>
                <!-- <van-radio name="2">初中</van-radio> -->
              </van-radio-group>
            </div>
            <!-- <div class="hits-span" v-if="form.grade == 1"><van-icon name="warning" /> 拟就读初一学生，请联系所在行政区教育局</div> -->
          </div>
        </div>
        <div class="apply-line" @click="openPop('grades')">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>年级</div>
            <input class="apply-text" v-model="form.oldgradeClass" type="text" readonly placeholder="请输入">
          </div>
          <div class="icon-arrow">
            <img src="../assets/bd-kz-icon@2x.png" alt="">
          </div>
        </div>
      </div>
      <div class="apply-box">
        <div class="apply-title"><span></span>就读意向</div>

        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>学校一</div>
            <input class="apply-text" v-model="form.intentionSchool1" type="text" placeholder="请输入意向学校">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>学校二</div>
            <input class="apply-text" v-model="form.intentionSchool2" type="text" placeholder="请输入意向学校">
          </div>
        </div>
        <div class="apply-line">
          <div class="apply-line-left">
            <div class="apply-subtitle"><span>* </span>学校三</div>
            <input class="apply-text" v-model="form.intentionSchool3" type="text" placeholder="请输入意向学校">
          </div>
        </div>

      </div>

      <div class="apply-box" style="padding-bottom: 10px;">
        <div class="apply-title"><span></span>备注</div>
        <div class="apply-textarea">
          <textarea cols="30" v-model="form.remark" rows="5" placeholder="请输入"></textarea>
        </div>
        <div class="apply-hit">
          <span>提醒</span>
          1.因部分学校学位紧张，若无空余学位将就近统筹安排至行政区内其他学校.<br>
          2.若该学生有兄弟姐妹在厦就读，请填写姓名、台胞证号、就读学校及年级等相关信息.
        </div>
        <div class="promise-box">
        <van-checkbox v-model="checked" icon-size="16px" shape="square"></van-checkbox>
        <span>（请打勾）本人保证所填写的信息真实有效，一经查实有弄虚作假行为的，将取消其派位学校的学位，后果自行承担。</span>
      </div>
        <!-- <div class="btn-apply" @click="submits">提交申报</div> -->
        <div class="btns-wrap">
          <van-button class="btn-apply" :loading="loadding" @click="submits" color="#DD2B11" type="primary">提交申报</van-button>
        </div>
      </div>
    </div>
    <van-dialog v-model="showNotice" title="申请须知" @confirm="confirmNotice" :confirm-button-text="'我知道了（请点击）'">
      <div class="notice-boxs">
        <!-- <div class="notice-header">申请须知</div> -->
        <div class="notice-box">
          <p>申请人必须保证所填写的信息真实有效，一经查实有弄虚作假行为的，将取消其派位学校的学位，后果自行承担。</p>

        </div>
      </div>
    </van-dialog>
    <van-popup
      v-model="show"
      round
      :style="{ height: '30%' }"
      position="bottom">
      <div class="sexbox">
        <div class="sex-title">
          请选择
          <span @click="show = false"><img src="../assets/tc-gb-icon@2x.png" alt=""></span>
        </div>
        <div class="sex-list">
          <span :class="{'choseSex': i === sexIndex}"
          @click="choseSex(i)"
          v-for="(item, i) in sexList"
          :key="i">{{item.name}}</span>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="mapShow"
      :style="{ height: '50%' }"
      position="bottom">
        <!-- <txMap type="edit" :addressData="addressData" @address="getAddress"></txMap> -->
        <!-- <van-area title="标题" :area-list="areaList" :columns-num="2" @confirm="areaConfirm" @cancel="mapShow = false" /> -->
        <van-picker
          title="所在区"
          show-toolbar
          :columns="areaList"
          @confirm="areaConfirm"
          @cancel="mapShow = false"
        />
    </van-popup>

    <van-popup
      position="bottom"
      v-model="showRelation">
      <van-picker
        :title="popTitle"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
    <van-popup
      position="bottom"
      v-model="showTime">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmTime"
        @cancel="showTime = false"
      />
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
// import txMap from '../components/txmap.vue'
import { areaList } from '@vant/area-data'
export default {
  name: 'ApplyView',
  components: {
    // txMap
  },
  data () {
    return {
      checked:false,
      showNotice:true,
      otherGuardian: [],
      guardian: {
        appellation: '',
        name: '',
        dpt: '',
        contact: ''
      },
      show: false,
      typeList: [],
      sexList: [
        {
          name: '男',
          key: 1
        },
        {
          name: '女',
          key: 2
        }
      ],
      sexIndex: null,
      sex: '',
      mapShow: false,
      addressData: {
        place: '',
        location: [] // 活动地点坐标
      },
      areaList: [
        {
          text: '同安区',
          children: [
            {text: '新民镇'}, {text: '大同街道'}, {text: '祥平街道'}, {text: '西柯街道'}, {text: '洪塘镇'}, {text: '汀溪镇'}, {text: '莲花镇'}, {text: '五显镇'}
          ]
        },
        {
          text: '海沧区',
          children: [
            {text: '新阳街道'}, {text: '海沧街道'}, {text: '东孚街道'}, {text: '嵩屿街道'}
          ]
        },
        {
          text: '思明区',
          children: [
            {text: '鹭江街道'},{text: '滨海街道'}, {text: '夏港街道'}, {text: '筼筜街道'}, {text: '中华街道'}, {text: '梧村街道'}, {text: '莲前街道'}, {text: '开元街道'}, {text: '嘉莲街道'}, {text: '鼓浪屿街道'}
          ]
        },
        {
          text: '湖里区',
          children: [
            {text: '江头街道'}, {text: '金山街道'}, {text: '禾山街道'}, {text: '湖里街道'}, {text: '殿前街道'}
          ]
        },
        {
          text: '翔安区',
          children: [
            {text: '香山街道'}, {text: '金海街道'}, {text: '民安街道'}, {text: '马巷街道'}, {text: '内厝镇'}, {text: '新店街道'}, {text: '大嶝街道'}, {text: '新圩镇'}, {text: '凤翔街道'}
          ]
        },
        {
          text: '集美区',
          children: [
            {text: '灌口镇'}, {text: '侨英街道'}, {text: '杏林街道'}, {text: '杏滨街道'}, {text: '集美街道'}, {text: '后溪镇'}
          ]
        }
      ],
      place: '',
      showRelation: false,
      columns: [],
      guardian1: [],
      guardian2: [],
      currentDate: [],
      minDate: new Date(2001, 0, 1),
      maxDate: new Date(),
      types: '',
      relation1: '',
      relation2: '',
      showTime: false,
      gradeClass: '',
      form: {
        name: '',
        tel: '',
        relationship: '',
        childrenName: '',
        childSex: '',
        childbrith: '',
        twId: '',
        residenceId: '',
        houseType: 1,
        place: '',
        detailPlace: '',
        propertyId: '',
        propertyName: '',
        propertyRelation: '',
        relation1: '',
        relation1Name: '',
        relation1TwId: '',
        relation1ResidenceId: '',
        relation1Company: '',
        relation1Job: '',
        relation1Tel: '',
        relation2: '',
        relation2Name: '',
        relation2TwId: '',
        relation2ResidenceId: '',
        relation2Company: '',
        relation2Job: '',
        relation2Tel: '',
        oldSchool: '',
        oldclass: '',
        class: '',
        grade: '0',
        oldgradeClass: '',
        intentionSchool1: '',
        intentionSchool2: '',
        intentionSchool3: '',
        remark: '',
        relation1Jobs: '',
        relation2Jobs: '',
        relation1s: '',
        relation2s: ''
      },
      classList: [],
      job: [],
      popTitle: '',
      first: true,
      hasGuardian2: 1,
      loadding: false,
      telKeyboard: false
    }
  },
  mounted () {
    this.getItem()
  },
  methods: {
    addGuardian () {
      let guardian = JSON.parse(JSON.stringify(this.guardian))
      this.otherGuardian.push(guardian)
    },
    delGuardian (i) {
      this.otherGuardian.splice(i, 1)
    },
    openPop (type) {
      switch (type) {
        case 'sex':
          this.show = true
          break;
        case 'map':
          this.mapShow = true
          break;
        case 'relation1':
          this.types = 'relation1'
          this.columns = this.guardian1
          this.popTitle = '关系'
          this.showRelation = true
          break;
        case 'relation2':
          this.types = 'relation2'
          this.columns = this.guardian2
          this.popTitle = '关系'
          this.showRelation = true
          break;
        case 'time':
          this.showTime = true
          break;
        case 'grades':
          this.types = 'grades'
          this.popTitle = '年级'
          this.columns = []
          this.columns = this.classList[this.form.grade].list
          this.showRelation = true
          break;
        case 'job1':
          this.types = 'job1'
          this.columns = this.job
          this.popTitle = '职务'
          this.showRelation = true
          break;
        case 'job2':
          this.types = 'job2'
          this.popTitle = '职务'
          this.columns = this.job
          this.showRelation = true
          break;
      }
    },
    choseSex (i) {
      this.sexIndex = i
      this.form.childSex = this.sexList[i].name
      this.form.sex = this.sexList[i].key
      this.show = false
    },
    areaConfirm (arr) {
      this.form.place = arr[0] + '-' + arr[1]
      console.log(arr)
      this.mapShow = false
    },
    onConfirm (e) {
      if (this.types === 'relation1') {
        this.form.relation1 = e
      }
      if (this.types === 'relation2') {
        this.form.relation2 = e
      }
      if (this.types === 'grades') {
        this.form.oldgradeClass = e
      }
      if (this.types === 'job1') {
        this.form.relation1Job = e
      }
      if (this.types === 'job2') {
        this.form.relation2Job = e
      }
      this.showRelation = false
    },
    onCancel (e) {
      this.showRelation = false
    },
    onChange () {},
    confirmTime (e) {
      console.log(e)
      let time = new Date(e)
      let year = time.getFullYear()
      let moth = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
      let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
      this.form.childbrith = year + '-' + moth + '-' + day
      this.showTime = false
    },
    changeClass (e) {
      console.log(e)
      if (!this.first) {
        this.form.oldgradeClass = ''
      }
      this.first = false
    },
    submits () {
      this.loadding = true
      if (!this.form.name) {
        this.loadding = false
        return this.$toast('填报人姓名不能为空')
      }
      if (!this.form.tel) {
        this.loadding = false
        return this.$toast('填报人电话不能为空')
      }
      if (!this.form.relationship) {
        this.loadding = false
        return this.$toast('填报人与儿童关系不能为空')
      }
      if (!this.form.childrenName) {
        this.loadding = false
        return this.$toast('适龄儿童姓名不能为空')
      }
      if (!this.form.childSex) {
        this.loadding = false
        return this.$toast('适龄儿童性别不能为空')
      }
      if (!this.form.childbrith) {
        this.loadding = false
        return this.$toast('适龄儿童生日不能为空')
      }
      if (!this.form.twId) {
        this.loadding = false
        return this.$toast('适龄儿童台胞证不能为空')
      }
      if (!this.form.place) {
        this.loadding = false
        return this.$toast(`${this.form.houseType === '1' ? '购房' : ''}地址不能为空`)
      }
      if (!this.form.detailPlace) {
        this.loadding = false
        return this.$toast('详细地址不能为空')
      }
      console.log(this.form.houseType)
      if (this.form.houseType == '1') {
        if (!this.form.propertyId) {
          this.loadding = false
          return this.$toast('产权证不能为空')
        }
        if (!this.form.propertyName) {
          this.loadding = false
          return this.$toast('产权人姓名不能为空')
        }
        if (!this.form.propertyRelation) {
          this.loadding = false
          return this.$toast('产权人与儿童关系不能为空')
        }
      }
      if (!this.form.relation1) {
        this.loadding = false
        return this.$toast('监护人1关系不能为空')
      }
      console.log(!this.form.relation1 === '其他', !this.form.relation1s)
      if (this.form.relation1 === '其他' && !this.form.relation1s) {
        this.loadding = false
        return this.$toast('监护人1其他关系不能为空')
      }
      if (!this.form.relation1Name) {
        this.loadding = false
        return this.$toast('监护人1姓名不能为空')
      }
      if (!this.form.relation1TwId) {
        this.loadding = false
        return this.$toast('监护人1台胞证不能为空')
      }
      if (!this.form.relation1Company) {
        this.loadding = false
        return this.$toast('监护人1单位不能为空')
      }
      if (!this.form.relation1Job) {
        this.loadding = false
        return this.$toast('监护人1职务不能为空')
      }
      if (this.form.relation1Job === '其他' && !this.form.relation1Jobs) {
        this.loadding = false
        return this.$toast('监护人1其他职务不能为空')
      }
      if (!this.form.relation1Tel) {
        this.loadding = false
        return this.$toast('监护人1电话不能为空')
      }
      if (this.hasGuardian2 !== 2) {
        if (!this.form.relation2) {
          this.loadding = false
          return this.$toast('监护人2关系不能为空')
        }
        if (this.form.relation2 === '其他' && !this.form.relation2s) {
          this.loadding = false
          return this.$toast('监护人2其他关系不能为空')
        }
        if (!this.form.relation2Name) {
          this.loadding = false
          return this.$toast('监护人2姓名不能为空')
        }
        if (!this.form.relation2TwId) {
          this.loadding = false
          return this.$toast('监护人2台胞证不能为空')
        }
        if (!this.form.relation2Company) {
          this.loadding = false
          return this.$toast('监护人2单位不能为空')
        }
        if (!this.form.relation2Job) {
          this.loadding = false
          return this.$toast('监护人2职务不能为空')
        }
        if (this.form.relation2Job === '其他' && !this.form.relation2Jobs) {
          this.loadding = false
          return this.$toast('监护人2其他职务不能为空')
        }
        if (!this.form.relation2Tel) {
          this.loadding = false
          return this.$toast('监护人2电话不能为空')
        }
      }
      if (!this.form.oldSchool) {
        this.loadding = false
        return this.$toast('原就读学校不能为空')
      }
      if (!this.form.class) {
        this.loadding = false
        return this.$toast('原就读年级不能为空')
      }
      if (!this.form.grade) {
        this.loadding = false
        return this.$toast('申请就读学校不能为空')
      }
      if (!this.form.oldgradeClass) {
        this.loadding = false
        return this.$toast('申请就读年级不能为空')
      }
      if (!this.form.intentionSchool1) {
        this.loadding = false
        return this.$toast('意向学校1不能为空')
      }
      if (!this.form.intentionSchool2) {
        this.loadding = false
        return this.$toast('意向学校2不能为空')
      }
      if (!this.form.intentionSchool3) {
        this.loadding = false
        return this.$toast('意向学校3不能为空')
      }
      if (!this.checked) {
        this.loadding = false
        return this.$toast('请同意个人承诺')
      }
      this.applyFun()
    },
    applyFun () {
      let params = {
        InformantName: this.form.name, // 填报人姓名
        InformantPhone: this.form.tel, // 填报人电话
        relation: this.form.relationship, // 填报人关系
        name: this.form.childrenName, // 姓名
        sex: this.form.sex, // 性别
        birthDay: this.form.childbrith, // 出生日期
        twIdcard: this.form.twId, // 台胞证号码
        residenceCard: this.form.residenceId, // 居住证
        hoseType: this.form.houseType, // 住房性质
        area: this.form.place, // 在厦居住所在区
        address: this.form.detailPlace, // 详细地址
        propertyCard: this.form.propertyId, // 产权证号
        propertyName: this.form.propertyName, // 产权人姓名
        propertyrelation: this.form.propertyRelation, // 产权人与适龄儿童关系
        guardian: this.form.relation1 === '其他' ? this.form.relation1s : this.form.relation1, // 监护人1关系
        guardianname: this.form.relation1Name, // 监护人1姓名
        guardianworkAdress: this.form.relation1Company, // 监护人1单位或公司名称
        guardianPosition: this.form.relation1Job === '其他' ? this.form.relation1Jobs : this.form.relation1Job, // 监护人1职务
        guardiantwIdCard: this.form.relation1TwId, // 监护人1台胞证
        guardianResidenceCard: this.form.relation1ResidenceId, // 监护人1居住证
        guardianphone: this.form.relation1Tel, // 监护人1联系电话
        oldSchool: this.form.oldSchool, // 原就读学校
        oldClass: this.form.class, // 原就读年级
        ApplyClass: this.form.grade, // 申请就读年级
        class: this.form.oldgradeClass, // 年级
        school1: this.form.intentionSchool1, // 意向学校一
        school2: this.form.intentionSchool2, // 意向学校二
        school3: this.form.intentionSchool3, // 意向学校三
        reason: this.form.remark, // 申请备注
      }
      if (this.form.houseType == 2) {
        params.propertyCard = '' // 产权证号
        params.propertyName = '' // 产权人姓名
        params.propertyrelation = '' // 产权人与适龄儿童关系
      }
      if (this.hasGuardian2 !== 2) {
        params.guardian1 = this.form.relation2 === '其他' ? this.form.relation2s : this.form.relation2, // 监护人2关系
        params.guardianname1 = this.form.relation2Name // 监护人2姓名
        params.guardianworkAdress1 = this.form.relation2Company // 监护人2单位
        params.guardianPosition1 = this.form.relation2Job === '其他' ? this.form.relation2Jobs : this.form.relation1Job // 监护人2职务
        params.guardiantwIdCard1 = this.form.relation2TwId // 监护人2台胞证
        params.guardianResidenceCard1 = this.form.relation2ResidenceId // 监护人2居住证
        params.guardianphone1 = this.form.relation2Tel // 监护人2电话
      } else {
        params.guardian1 = '', // 监护人2关系
        params.guardianname1 = '' // 监护人2姓名
        params.guardianworkAdress1 = '' // 监护人2单位
        params.guardianPosition1 = '' // 监护人2职务
        params.guardiantwIdCard1 = '' // 监护人2台胞证
        params.guardianResidenceCard1 = '' // 监护人2居住证
        params.guardianphone1 = '' // 监护人2电话
      }

      if (this.$route.query.id) {
        params.id = Number(this.$route.query.id)
        this.$api.updataStudents(params).then(res => {
          this.loadding = false
          if (res.code === this.$config.SUCCESS_CODE) {
            this.$toast('修改成功！')
            this.$router.go(-1)
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.$api.addStudents(params).then(res => {
          this.loadding = false
          if (res.code === this.$config.SUCCESS_CODE) {
            this.$toast('申请成功！')
            this.$router.go(-1)
          } else {
            this.$toast(res.message)
          }
        })
      }
    },
    getItem () {
      let params = {
        key: 'applyclass'
      }
      this.$api.getItem(params).then(res => {
        if (res.code === this.$config.SUCCESS_CODE) {
          if (res.data) {
            this.classList = JSON.parse(res.data)
            console.log(this.classList)
          }
          this.getRelationship()
        }
      })
    },
    getRelationship () {
      let params = {
        key: 'relationship'
      }
      this.$api.getItem(params).then(res => {
        if (res.code === this.$config.SUCCESS_CODE) {
          if (res.data) {
            let arr = JSON.parse(res.data)
            this.guardian1 = JSON.parse(JSON.stringify(arr))
            this.guardian2 = JSON.parse(JSON.stringify(arr))
          }
          this.getJob()
        }
      })
    },
    getJob () {
      let params = {
        key: 'job'
      }
      this.$api.getItem(params).then(res => {
        if (res.code === this.$config.SUCCESS_CODE) {
          if (res.data) {
            this.job = JSON.parse(res.data)
          }
          if (this.$route.query.id) {
            this.getDetail(this.$route.query.id)
          } else {
            this.first = false
          }
        }
      })
    },
    getDetail (id) {
      let params = {
        id: id
      }
      this.$api.getStudentsDetail(params).then(res => {
        if (res.code === this.$config.SUCCESS_CODE) {
          console.log(res)
          this.form = {
            name: res.data.InformantName,
            tel: res.data.InformantPhone,
            relationship: res.data.relation,
            childrenName: res.data.name,
            childSex: '',
            sex: res.data.sex,
            childbrith: res.data.birthDay,
            twId: res.data.twIdcard,
            residenceId: res.data.residenceCard,
            houseType: res.data.hoseType,
            place: res.data.area,
            detailPlace: res.data.address,
            propertyId: res.data.propertyCard,
            propertyName: res.data.propertyName,
            propertyRelation: res.data.propertyrelation,
            relation1: res.data.guardian,
            relation1Name: res.data.guardianname,
            relation1TwId: res.data.guardiantwIdCard,
            relation1ResidenceId: res.data.guardianResidenceCard || '',
            relation1Company: res.data.guardianworkAdress,
            relation1Job: res.data.guardianPosition,
            relation1Tel: res.data.guardianphone,
            relation2: res.data.guardian1,
            relation2Name: res.data.guardianname1 || '',
            relation2TwId: res.data.guardiantwIdCard1 || '',
            relation2ResidenceId: res.data.guardianResidenceCard1 || '',
            relation2Company: res.data.guardianworkAdress1 || '',
            relation2Job: res.data.guardianPosition1 || '',
            relation2Tel: res.data.guardianphone1 || '',
            oldSchool: res.data.oldSchool,
            class: res.data.oldClass,
            grade: res.data.ApplyClass,
            oldgradeClass: res.data.class,
            intentionSchool1: res.data.school1,
            intentionSchool2: res.data.school2,
            intentionSchool3: res.data.school3,
            remark: res.data.reason || '',
            userId: res.data.userId
          }
          let flags = false
          for (let i in this.job) {
            if (this.job[i] === this.form.relation1Job) {
              flags = true
            }
          }
          if (!flags) {
            this.form.relation1Jobs = res.data.guardianPosition
            this.form.relation1Job = '其他'
          }
          let flags1 = false
          for (let i in this.job) {
            if (this.job[i] === this.form.relation2Job) {
              flags1 = true
            }
          }
          if (!flags1) {
            this.form.relation2Jobs = res.data.guardianPosition1
            this.form.relation2Job = '其他'
          }
          if (!this.form.relation2) {
            this.hasGuardian2 = 2
          } else {
            this.hasGuardian2 = 1
          }
          // 关系
          let guardian1 = false
          for (let i in this.guardian1) {
            if (this.guardian1[i] === this.form.relation1) {
              guardian1 = true
            }
          }
          if (!guardian1) {
            this.form.relation1s = res.data.guardian
            this.form.relation1 = '其他'
          }
          // 关系
          let guardian2 = false
          for (let i in this.guardian2) {
            // console.log('gggg', this.form.relation2, this.relation2[i], this.relation2[i] === this.form.relation2)
            if (this.guardian2[i] === this.form.relation2) {
              guardian2 = true
            }
          }
          if (!guardian2) {
            this.form.relation2s = res.data.guardian1
            this.form.relation2 = '其他'
          }


          for (let i in this.sexList) {
            if (this.sexList[i].key === res.data.sex) {
              this.form.childSex = this.sexList[i].name
            }
          }
          this.first = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  background: #F4F4F4;
  min-height: 100vh;
}
.banner {
  position: relative;
  z-index: 10;
}
.banner img {
  width: 100%;
}
.apply-wrap {
  margin-top: -13px;
  position: relative;
  z-index: 20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.apply-box {
  padding: 0 15px;
  background: #fff;
  margin-bottom: 8px;
}
.apply-title {
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-top: 19px;
}
.apply-title span {
  background: #DD2B11;
  height: 25px;
  width: 5px;
  border-radius: 2.5px;
  display: inline-block;
  margin-right: 10px;
}
.apply-subtitle {
  color: #333333;
  font-size: 16px;
  text-align: left;
  height: 50px;
  line-height: 50px;
  position: relative;
}
.apply-subtitle span {
  color: #D8302A;
}
.apply-text {
  border: 0;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
}
.apply-line {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #F0F0F0;
}
.apply-line-left {
  flex: 1;
  padding-bottom: 10px;
}
.icon-arrow {
  img {
    width: 13px;
    height: 13px;
  }
}
.apply-inputs {
  height: 51px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  span {
    color: #D8302A;
    margin-right: 3px;
  }
  input {
    border: 0;
    flex: 1;
    height: 100%;
  }
  .positions {
    color: #333333;
    font-size: 16px;
  }
}
.apply-tops {
  display: flex;
  justify-content: space-between;
  .add-iocn {
    margin-top: 18px;
    display: flex;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
      margin-right: 3px;
    }
    span {
      color: #3E4FFF;
      font-size: 13px;
    }
  }
}
.apply-textarea {
  padding-top: 30px;
  textarea {
    border: 1px solid #F0F0F0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
}
.apply-hit {
  padding: 20px;
  border: 1px solid #F0F0F0;
  position: relative;
  margin: 20px auto;
  text-align: left;
  // text-indent: 2em;
  font-size: 14px;
  line-height: 25px;
  span {
    text-indent: 0;
    color: #D8302A;
    position: absolute;
    top: -13px;
    left: 20px;
    font-size: 18px;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
  }
}
.btns-wrap {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.btn-apply {
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #DD2B11;
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  width: 100%;
}
.del-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  color: #D8302A;
  font-size: 13px;
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }
}
.sexbox {
  .sex-title {
    height: 50px;
    line-height: 50px;
    position: relative;
    text-align: left;
    padding: 0 22px;
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      padding: 0 12px;
      box-sizing: border-box;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .sex-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      height: 45px;
      line-height: 45px;
      text-align: left;
      padding: 0 22px;
    }
    .choseSex {
      background: #EEEEEE;
      color: #3E4FFF;
    }
  }
}
.relation-input {
  border: 0;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
}
.hits-span {
  color: #D8302A;
  font-size: 14px;
  padding-top: 10px;
  text-align: left;
}
.promise-box {
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom:15px;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  align-items: baseline;
  span {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    color: #D8302A;
  }
}
.notice-boxs{
  padding: 15px;
}
.notice-boxs p{
  line-height: 1.6;
  text-align: left;
}
</style>
