<template>
  <div class="taibao-wrap">
    <div class="bj">
      <!-- <img src="../assets/twznjjxxt-bj@2x.png" alt=""> -->
      <img class="logos" src="../assets/logo@2x.png" alt="">
      <img src="../assets/words.png" alt="">
    </div>
    <div class="info">
      <div class="btn-applys" @click="goApply">我要申报</div>
      <div class="btn-update" @click="goUpdata">我要修改</div>
    </div>
    <div class="tel-box">
      咨询电话：0592-2856590
    </div>
    <van-dialog v-model="showDialog" title="提示" show-cancel-button>
      <div class="hit-boxs">{{msg}}</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      showDialog: false,
      isUpdata: false,
      isCreate: false,
      msg: '',
      id: '',
      opens: true
    }
  },
  mounted () {
    if (this.$utils.storage('get', 'userinfo')) {
      this.isOpen()
      this.getList()
    }
    this.getShare()
  },
  methods: {
    goApply () {
      if (!this.opens) {
        this.msg = '暂未开放'
        this.showDialog = true
        return
      }
      if (!this.isCreate) {
        this.msg = '您已申请，不要重复申请'
        this.showDialog = true
        return
      }
      this.$router.push({
        path: '/apply'
      })
    },
    goUpdata () {
      if (!this.opens) {
        this.msg = '暂未开放'
        this.showDialog = true
        return
      }
      if (!this.isUpdata) {
        this.msg = '您还未申请, 请先申请'
        this.showDialog = true
        return
      }
      this.$router.push({
        path: '/apply',
        query: {
          id: this.id
        }
      })
    },
    getList () {
      this.$api.getStudentList({}).then(res => {
        console.log(res)
        if (res.code === this.$config.SUCCESS_CODE) {
          let datas = new Date()
          let year = datas.getFullYear()
          if (res.data.length > 0) {
            let flag = false
            for (let i in res.data) {
              if (new Date(res.data[i].createTime.replace(/-/g, "/")).getTime() - new Date(`${year}/01/01 00:00:00`) > 0) {
                flag = true
                this.id = res.data[i].id
              }
            }
            console.log('success', flag)
            if (flag) {
              this.isCreate = false
              this.isUpdata = true
            } else {
              this.isCreate = true // 可以创建
              this.isUpdata = false // 可以修改
            }
          } else {
            this.isCreate = true // 可以创建
            this.isUpdata = false // 可以修改
          }
          console.log('this.isCreate', this.isCreate, this.isUpdata)
        }
      })
    },
    getShare () {
      let title = '台胞(台商)子女就学网上备案系统'
      let infoDesc=''
      let infoLink = window.location.href
      let infoImg = require('../assets/logo.jpg')
      // this.$wxShare.getConfig(title, infoDesc, infoLink, infoImg)
    },
    isOpen () {
      let params = {
        key: 'isOpen'
      }
      this.$api.getItem(params).then(res => {
        if (res.code === this.$config.SUCCESS_CODE) {
          if (res.data) {
            let data = JSON.parse(res.data)
            this.opens = data.isOpen == '0' ? true : false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.taibao-wrap {
  position: relative;
  .info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 220px;
    .btn-applys {
      background: linear-gradient(-1deg, #FFD248 0%, #FFAD34 100%);
      border-radius: 22.5px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      height: 45px;
      line-height: 45px;
      width: 70%;
      margin: 0 auto;
    }
    .btn-update {
      background: #FFFFFF;
      border-radius: 22.5px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      height: 45px;
      line-height: 45px;
      width: 70%;
      margin: 25px auto;
      color: #3E4FFF;
    }
  }
  .tel-box {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
  }
}
.bj {
  height: 100vh;
  overflow: hidden;
  background: url('../assets/twznjjxxt-bj@2x.png') no-repeat center;
  background-size: cover;
  img {
    margin-bottom: 12px;
    width: 100%;
  }
  .logos {
    margin-top: 50px;
  }
}
.hit-boxs {
  padding: 20px 0;
  font-size: 14px;
  color: #646566;
}
</style>
