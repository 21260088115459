
import { Toast } from 'vant'
var { storage } = require('../utils')
var config = require('../config')
var router = require('../router')
var Fly = require('flyio/dist/npm/fly')
var fly = new Fly()

fly.config.timeout = 100000
fly.config.baseURL = config.BASE_URL
fly.interceptors.request.use((c) => {
  if (storage('get', 'userinfo')) {
    console.log(storage('get', 'userinfo').token)
    const token = storage('get', 'userinfo').data.token
    if (typeof token === 'string') {
      c.headers['Authorization'] = token
    }
  }
  // c.headers['Content-Type'] = config.REQUEST_CONTENT_TYPE
  return c
}, (error) => {
  // Indicator.close()
  return Promise.reject(error)
})

// 返回状态判断
fly.interceptors.response.use((res) => {
  let hasSessionId
  if (res.data && res.data.hasData) {
    hasSessionId = res.data.hasData
  }
  // 登录过期或无sessionId跳转登录页面
  if (res.data === config.SESSION_ID_OVERDUE_VALUE || hasSessionId === 0 || res.data.respCode === '401') {
    // Toast('无权限，登陆失效')
    storage('remove', 'userinfo')
    location.reload()
    // setTimeout(() => {
    //   router.default.push('/login')
    // }, 1500)
  } else {
    return res
  }
}, (error) => {
  console.log(error.response)
  if (error.response && error.response.status === 401) {
    // Toast('无权限，登陆失效')
    storage('remove', 'userinfo')
    location.reload()
    // setTimeout(() => {
    //   router.default.push('/login')
    // }, 1500)
  }
  return Promise.reject(error)
})

export function fetchpost (url, params, header) {
  return new Promise((resolve, reject) => {
    fly.post(url, params, header)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchput (url, params, header) {
  return new Promise((resolve, reject) => {
    fly.put(url, params, header)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getpost (url, params) {
  return new Promise((resolve, reject) => {
    fly.post(url, params)
      .then(response => {
        if (response.status) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchget (url, params) {
  return new Promise((resolve, reject) => {
    fly.get(url, params)
      .then(response => {
        if (response) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchdelete (url, params) {
  return new Promise((resolve, reject) => {
    let option = []
    for (var key in params) {
      option.push(`${key}=${params[key]}`)
    }
    option = option.join('&')
    fly.delete(`${url}?${option}`)
      .then(response => {
        if (response) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
