import config from '@/config'
import wx from 'weixin-js-sdk'
import api from './api'

/**
 *分享
 * @param _this
 * @param infoTitle 标题
 * @param infoLink 链接
 * @param infoImg 图片
 * @param infoDesc 描述
 */
export default {
  getConfig (infoTitle, infoDesc, infoLink, infoImg) {  // 自定义分享所需要的参数
    let params = {
      url: encodeURIComponent(infoLink)
    }
    api.getSignature(params).then(res => {
      if (res.code === config.SUCCESS_CODE) {
        wx.config({
          appId: res.data.appId, // 必填，公众号的唯一标识（公众号的APPid）
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
              'onMenuShareTimeline', // 分享给好友
              'onMenuShareAppMessage', // 分享到朋友圈
              'updateAppMessageShareData', // 分享给好友1.4
              'updateTimelineShareData' // 分享到朋友圈1.4
          ], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });
        wx.ready(function() {
          var shareData = {
            title: infoTitle, // 分享标题
            desc: infoDesc, // 分享描述
            link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: infoImg, // 分享图标
            success: function(res) {
              console.log('333', res)
            }
          };
          //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          wx.updateAppMessageShareData(shareData);
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(shareData);
        })
      }
    })
    // 从后台获取签名等内容，其中getSignature为本人自己封装请求，可以按需自己修改。
  }
}
