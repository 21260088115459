<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
    var is_weixin = (function () {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    })();
    console.log('location', this.$route.query)
    //  this.testLogin()
    if (is_weixin) {
      if (this.$route.query.code) {
        console.log('sss', this.$route.query.code)
        this.wechartLogin(this.$route.query.code);
      } else {
        if (this.$utils.storage('get', 'userinfo')) {

        } else {
          if (this.GetUrlParam("code").length > 0) {

          } else {
            let url = this.$utils.processUrloa(location.href);
            let REDIRECT_URI = encodeURIComponent(url);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.$config.CORPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${this.$config.SCOPE}&state=STATE#wechat_redirect`;
          }
        }
      }
    }
  },
  methods: {
    wechartLogin (code) {
      let params = {
        code: code
      }
      // 储存 userInfo
      this.$api.login(params).then(res => {
        console.log('44444')
        if (res.code === this.$config.SUCCESS_CODE) {
          this.$utils.storage('set', 'userinfo', res)
        }
      })
    },
    GetUrlParam(paraName) {
      var url = document.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return '';
      } else {
        return '';
      }
    },
    testLogin () {
      let params = {
        code: '111'
      }
      // 储存 userInfo
      this.$api.testLogin(params).then(res => {
        console.log('44444')
        if (res.code === this.$config.SUCCESS_CODE) {
          this.$utils.storage('set', 'userinfo', res)
        }
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 640px;
  margin: 0 auto;
}
body {
  margin: 0;
}
</style>
